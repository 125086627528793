/* .amplify-tabs {
  display: none;
} */
@import 'theme.scss';


body, html, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.paddingSection {
  padding: 5% 15%;
}

.loneSection {
  padding: 5% 15%;
  min-height: 500px;
}

// p {
//   max-width: 1800px;
//   margin: auto;
// }

// h1 {
//   max-width: 1800px;
//   margin: auto;
// }


body,html {
  background-color: $footer-color;
}

.paddingSection {
  padding: 5%;
}

.thanksForSubscribing {
  display:none;
  margin-top: 10px;
}

.App {
  margin: 0;
  padding: 0;
  background-color: $footer-color;
}

.landingContainer {
  display: flex;
  width: 100%;
  padding: 5%;
  box-sizing: border-box;
  column-gap: 35px;
  max-width: 2800px;
  align-items: center;
}

.leftLeading {

  box-sizing: border-box;
  //padding-right: 40px;
  height: 100%;
  width: 50%;
  margin-right:0;
}



.rightLeading {
  width: 50%;
  height: 100%;
  vertical-align:middle;  
}

.mainScreenshot {
  width: 100%;
  max-width: 1000px;
  display:block;
  margin: auto;
}

.errorMessage {
  width: auto;
  color: $errormessage-color;
  padding: 5px;
}

.App {
  margin: 0;
  padding: 0;
  background: $main-background;
  font-family: 'Avenir Next';
}

.warning {
  color: yellow;
}

/* ---------- HEADER ----------- */

.headerButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: 20px;

}

.App-header {
  display: flex;
  justify-content: space-between;
  padding: 1% 4%;
  background-color: $header-background;
}


/* ------------------------------ */


/* ---------- FOOTER ----------- */

// fixed position footer:
// footer {
//   position: fixed;
//   width: 100%;
//   bottom: 0;
// }

footer {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  background-color: $footer-color;
  color: #fff;
  text-align: center;
}


.App-footer {

  // padding-top: 10px;
}

footer a {
  color: #fff;
  text-align: center;
}

.delimiter {
  width: 100%;
  height: 10px;
  background-color: $main-buttoncolor;
}

footer p {
  color: #fff;
  text-align: center;

}

.section:not(.landingContainer) {
  box-sizing: border-box;
  display: flex;
  background: #fff;
  gap: 35px;
  align-items: center;
    img {
      width: 50%;
    }
}

.functionsWrapper {
  margin: auto;
  max-width: 1400px;
}

.coloredSection {
  color: #fff;
  background: #bfc7e2;

  h2 {
    color: #363636;
    margin-top: 0;
  }

  p {
    font-weight: 600;
    text-align: center;
  }
}

.smallImage {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #363636;
}

/* ------------------------------ */

input[type='text'],
input[type='email'],
input[type='password'],
textarea { /* I think we need 16px here to prevent Safari on iOS from zooming in on inputs: */
  font-size: 16px;
} 


.App-button {
  border: none;
  color: $button-textcolor;
  background-color: $main-buttoncolor;
  border-radius: 0 36px 36px 0;
  text-decoration: none;
  margin-right: 15px;
  padding: 1.5vh 1.5vw;
  cursor: pointer;
  font-size: 14px;
}

.subscriptionSection {
  display: flex;
  margin-bottom: 12px;
  input {
    width: 60%;
    padding: 15px;
    border: none;
    border-radius: 36px 0 0 36px;
  }
}

.subscriptionSection + em,
.subscriptionSection + em a {
  color: #777;
  font-size: 13px;
}

.mainwrapper {
  // padding-top:2%;
  // padding-right:2%;
  // padding-left:2%;
  //margin: auto;
  position: relative;
  // min-height: 100%;
  margin: 0;
}

.first-layout {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
}

.first-layout > div {
  background: #f1f3f4;
  border-radius: 10px;
  border: 1px solid #363636;
}

.infotext {
  padding-bottom: 30px;
}

h1 {
  color: #363636;
  font-size: 2.4em;
  margin-top: 0;
}

h2 {
  color: #363636;
  font-weight: 600;
  font-size: 2.1em;
  margin-top: 0;
}

p {
  color: $p-color;
  font-size: 1.2em;
  line-height: 200%;
}

.mainpart {
  width: 50%;
}


/* RESPONSIVE */
@media screen and (max-width: 800px) {

  body {
    background-color: $footer-color;
  }


  .landingContainer {
    flex-direction: column-reverse;
  }

  .mainScreenshot {
    margin-bottom: 20px;
  }
  .subscriptionSection {
    width: 100%;
    display:flex;

    row-gap: 10px;
    margin: auto;
    input {
      width: 90%;
    }
    .App-button {
      width: 50%;
      margin: 0;
    }
  }

  .leftLeading {
    width: 100%;
    box-sizing: border-box;
    
  }

  .rightLeading {
    width: 100%;
  }

  .mainpart {
    // margin-bottom: 30px;
    
  }

  .infotext {
    padding-bottom: 0px;
  }

  

  .mainpart, .imgwrapper, .container {
    width: auto;
  }

  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 22px;
  }

  p {
    font-size: 15px;
  }

  input {
    width: 80%;
  }

  .App-footer {
    // margin-top: 20px;
    box-sizing: border-box;
    margin: 0;
  }

  

  .section:not(.landingContainer) {
    padding: 5%;
    display: block!important;
    
    img {
      width: 100%!important;
      margin-bottom: 20px;
    }
    p:last-child {
      margin-bottom: 0;
    }

    .infotext + img {
      padding-top: 20px;
      margin-bottom: 0;
    }
  }

  .first-layout {
    grid-gap: 20px!important;
  }
}

